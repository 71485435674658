export var settings = {
  dots: true,
  infinite: false,
  arrows: false,
  pauseOnHover: true,
  speed: 1000,
  slidesToShow: 2,
  slidesToScroll: 1,
  initialSlide: 0,
  cssEase: "linear",

  responsive: [
    {
      breakpoint: 920,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
  ],
};
